import { spy } from 'ui-utilities';

export default function AnchorNav(el, {
    menuToggleHandle,
    activeClass,
    fixedClass,
    actions,
    events,
}) {
    const links = el.querySelectorAll('nav a');
    const menuToggle = el.querySelector(menuToggleHandle);
    let offsetHeight = 0;
    events.emit(actions.getHeaderHeight, {
        cb: headerHeight => {
            offsetHeight = headerHeight;
        },
    });

    const handleLinks = Array.from(links).map(l => e => {
        e.preventDefault();

        const target = document.getElementById(l.getAttribute('href').split('#')[1]);

        if (!target) return;

        target.scrollIntoView({ behavior: 'smooth' });

        handleMenuToggle();
    });
    function handleMenuToggle() {
        el.classList.toggle(activeClass);
    }
    function spyAnchorsCb(activeAnchor) {
        menuToggle.innerHTML = activeAnchor.innerHTML;
    }
    function handleNavObserver(entries) {
        el.classList.toggle(
            fixedClass,
            entries[0].boundingClientRect.top < offsetHeight,
        );
    }

    links.forEach((l, i) => {
        l.addEventListener('click', handleLinks[i]);
    });
    menuToggle.addEventListener('click', handleMenuToggle);
    spy.anchors({ links, activeClass, cb: spyAnchorsCb });
    const navObserver = new IntersectionObserver(handleNavObserver, {
        rootMargin: `-${offsetHeight}px 0px 0px 0px`,
        threshold: 1,
    });
    navObserver.observe(el);
}
