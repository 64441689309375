import Panzoom from '@panzoom/panzoom';

export default function RegionsMap(el, {
    navHandle,
    navToggleHandle,
    activeClass,
    notActiveClass,
    mapHandle,
    mapContHandle,
    mapShippingLocationsHandle,
    mapShippedHandle,
    testimonialContHandle,
    testimonialHandle,
    testimonialDetailCardHandle,
    footnoteHandle,
    regionViewClass,
    shippingLocationsViewClass,
    detailCardViewClass,
    showCoords,
}) {
    // Nav
    const nav = el.querySelector(navHandle);
    const navToggle = el.querySelector(navToggleHandle);
    const navCont = nav.querySelector('nav');
    const navButtons = navCont.querySelectorAll('button');

    // Map
    const map = el.querySelector(mapHandle);
    const mapCloseButton = map.querySelector('button');
    const mapCont = map.querySelector(mapContHandle);
    const mapCoords = mapCont.querySelector('span.coords');
    const mapShippingLocations = map.querySelector(mapShippingLocationsHandle);
    const mapShipped = map.querySelector(mapShippedHandle);
    const mapShippedToggleInput = mapShipped.querySelector('input[type="checkbox"]');
    const mapSvg = mapCont.querySelector('svg');
    const mapStatesCont = mapSvg.getElementById('State_Outlines');
    const mapStates = Array.from(mapStatesCont.children);
    const mapRegionsCont = mapSvg.getElementById('Region_Outlines');
    const mapRegions = mapRegionsCont.children;

    // Testimonial cards
    const testimonialsCont = map.querySelector(testimonialContHandle);
    const testimonials = map.querySelectorAll(testimonialHandle);
    const testimonialsButtons = map.querySelectorAll(`${testimonialHandle} button`);
    const testimonialsCards = map.querySelectorAll('.detail-card-cont');
    const testimonialsCloseButton = map.querySelector(`${testimonialDetailCardHandle} button`);

    // Footnote
    const footnote = el.querySelector(`${footnoteHandle} p`);

    // Ensures svg parent container size is the exact same as the svg
    const svgRect = mapSvg.getBoundingClientRect();
    const elementSameSize = [mapCont, mapShippingLocations, testimonialsCont];
    elementSameSize.forEach(el => {
        el.style.width = `${svgRect.width}px`;
        el.style.height = `${svgRect.height}px`;
    });

    // View state
    let isRegionView = false;
    let isShippedView = false;
    let isDetailCardView = false;

    // Set active region
    let activeRegionId = null;

    // Panzoom
    const pzOptions = {
        maxScale: 5,
        cursor: 'default',
        disablePan: true,
        disableZoom: true,
    };
    const pz = Panzoom(mapCont, pzOptions);

    mapCont.addEventListener('panzoomchange', event => {
        const { scale } = event.detail;

        testimonials.forEach(icon => {
            icon.style.transform = `scale(${1 / scale}) translate(-${50 * scale}%, -${50 * scale}%)`;
        });
    });

    testimonials.forEach(testimonial => {
        testimonial.style.pointerEvents = 'none';
    });

    function toggleClass(element, addClass, removeClass = null) {
        if (removeClass) {
            element.classList.remove(removeClass);
        }
        element.classList.add(addClass);
    }

    function handleButtonClick(button) {
        if (isShippedView) {
            mapShippingLocations.style.opacity = '0';
            isShippedView = mapShippedToggleInput.checked = false;
            map.classList.remove(shippingLocationsViewClass);
        }
        if (!isShippedView) {
            navButtons.forEach(b => b.classList.remove(activeClass));
            [...mapStates, ...mapRegions].forEach(element => {
                toggleClass(element, notActiveClass, activeClass);
            });
            toggleRegionClass(button, 'add', notActiveClass);
            footnote.innerText = 'Select a pin to see details';
            map.classList.add(regionViewClass);
        }
    }

    function handlePanzoomRegion(regionPath) {
        isRegionView = true;

        // Enable panning and zooming
        pz.setOptions({
            disablePan: false,
            disableZoom: false,
        });

        const svgScaleX = mapShippingLocations.offsetWidth / mapSvg.viewBox.baseVal.width;
        const svgScaleY = mapShippingLocations.offsetHeight / mapSvg.viewBox.baseVal.height;
        const label = regionPath.querySelector('rect');
        const { x: labelX, y: labelY, width: labelWidth, height: labelHeight } = label.getBBox();
        const normalizedLabelX = labelX * svgScaleX;
        const normalizedLabelY = labelY * svgScaleY;
        const normalizedLabelWidth = labelWidth * svgScaleX;
        const normalizedLabelHeight = labelHeight * svgScaleY;
        const centerX = mapShippingLocations.clientWidth / 2;
        const centerY = mapShippingLocations.clientHeight / 2;
        const panX = centerX - normalizedLabelX + normalizedLabelWidth / 2;
        const panY = centerY - normalizedLabelY + normalizedLabelHeight / 2;

        setTimeout(() => {
            pz.pan(panX, panY);
            pz.zoom(2, { animate: true });
        }, 100);
    }

    function handlePanzoomTestimonialButton(button) {
        const scale = pz.getScale();
        const {
            x: buttonX,
            y: buttonY,
            width: buttonWidth,
            height: buttonHeight,
        } = button.getBoundingClientRect();
        const {
            x: contX,
            y: contY,
            width: contWidth,
            height: contHeight,
        } = mapShippingLocations.getBoundingClientRect();
        const centerX = contWidth / 2;
        const centerY = contHeight / 2;
        const buttonOffsetLeft = buttonX - contX;
        const buttonOffsetTop = buttonY - contY;
        const panX = centerX - buttonOffsetLeft + buttonWidth / 2;
        let panY = centerY - buttonOffsetTop + buttonHeight / 2;

        if (window.innerWidth < 768) {
            panY -= button.classList.contains('testimonial') ? 150 : 100;
        }

        pz.pan(panX / scale, panY / scale);
        pz.zoom(3, { animate: true });
    }

    function handleActiveNavButton() {
        navButtons.forEach(button => {
            if (button.classList.contains(activeClass)) {
                button.classList.remove(activeClass);
                const regionId = button.getAttribute('data-region-id');
                const regionPath = mapSvg.getElementById(regionId);
                if (regionPath) {
                    regionPath.classList.remove(activeClass);
                    regionPath.classList.remove(notActiveClass);
                }
            }
        });
    }

    function handleNavToggleClick(event) {
        const clickedButton = event.target;
        nav.classList.toggle(activeClass);
        navToggle.innerHTML = clickedButton.innerHTML;
    }

    navToggle.addEventListener('click', handleNavToggleClick);

    function updateTestimonialButtons(regionId) {
        testimonialsButtons.forEach(button => {
            const buttonRegionId = button.getAttribute('data-region-id');
            const isActive = buttonRegionId === regionId;
            button.style.opacity = isActive ? '1' : '0';
            button.style.pointerEvents = isActive ? 'auto' : 'none';
        });
    }

    navButtons.forEach(button => {
        button.addEventListener('click', event => {
            hideTestimonialCard();
            handleNavToggleClick(event);
            handleButtonClick(button);

            const regionId = button.getAttribute('data-region-id');
            const regionPath = mapSvg.getElementById(regionId);
            handlePanzoomRegion(regionPath);

            activeRegionId = regionId;

            updateTestimonialButtons(regionId);
        });
    });

    mapCont.addEventListener('click', event => {
        const regionElement = event.target.closest('.region-path');

        if (regionElement) {
            const regionId = regionElement.getAttribute('data-region-id');
            const regionPath = mapSvg.getElementById(regionId);
            handlePanzoomRegion(regionPath);
        }
    });

    function toggleRegionClass(element, action, removeClass = null) {
        element.classList[action](activeClass);

        const regionId = element.getAttribute('data-region-id');
        const regionPath = mapSvg.getElementById(regionId);
        if (regionPath) {
            toggleClass(regionPath, activeClass, removeClass);
        }

        const statesId = element.getAttribute('data-states-id');
        const statesPath = mapSvg.getElementById(statesId);
        if (statesPath) {
            toggleClass(statesPath, activeClass, removeClass);
        }

        const matchingButton = Array.from(navButtons).find(button => button.getAttribute('data-region-id') === regionId);
        if (matchingButton) {
            matchingButton.classList[action](activeClass);
        }

        // Add inactive class to other regions
        Array.from(mapRegions).forEach(region => {
            if (region !== regionPath) {
                toggleClass(region, notActiveClass, activeClass);
            }
        });
    }

    function handleRegionClasses() {
        // Reset the map to its original state
        if (!isShippedView) {
            isRegionView = false;
            isDetailCardView = false;

            // Reset map to its original state if pz has been initialized
            pz.zoom(1, { animate: true });
            pz.reset();
            mapCont.style.cursor = 'default';

            navToggle.innerText = 'Select a Region';
            footnote.innerText = 'Select a region to see details';
            map.classList.remove(regionViewClass);
        }

        [...mapStates, ...mapRegions].forEach(element => {
            element.classList.remove(notActiveClass, activeClass);
        });

        handleActiveNavButton();
    }

    Array.from(mapStates).forEach(state => {
        state.addEventListener('mouseenter', () => {
            if (!isShippedView) {
                Array.from(mapStates).forEach(s => {
                    if (s !== state) {
                        toggleClass(s, notActiveClass, activeClass);
                        toggleRegionClass(s, 'remove', notActiveClass);
                    }
                });
                toggleRegionClass(state, 'add', notActiveClass);
            }
        });
    });

    // Let map regions be clickable in initial view state if `isShippedView` is false
    if (!isShippedView) {
        Array.from(mapStates).forEach(state => {
            state.addEventListener('click', () => {
                if (state.classList.contains('is-active')) {
                    const regionId = state.getAttribute('data-region-id');
                    const regionPath = mapSvg.getElementById(regionId);
                    const matchingButton = Array.from(navButtons).find(button => button.getAttribute('data-region-id') === regionId);
                    if (matchingButton) {
                        toggleRegionClass(matchingButton, 'add', notActiveClass);
                        map.classList.add(regionViewClass);
                        footnote.innerText = 'Select a pin to see details';
                        handlePanzoomRegion(regionPath);
                        activeRegionId = regionId;
                        updateTestimonialButtons(regionId);
                        isRegionView = true;

                        const navToggle = document.querySelector(navToggleHandle);
                        if (navToggle) {
                            navToggle.innerText = matchingButton.innerText;
                        }
                    }
                }
            });
        });
    }

    mapShippedToggleInput.addEventListener('click', () => {
        map.classList.toggle(shippingLocationsViewClass);
        mapShippingLocations.style.opacity = mapShippingLocations.style.opacity === '1' ? '0' : '1';

        isShippedView = mapShippedToggleInput.checked;

        handleRegionClasses();
    });

    function showTestimonialPinMarkers(regionId) {
        testimonialsButtons.forEach(btn => {
            const buttonRegionId = btn.getAttribute('data-region-id');
            if (buttonRegionId === regionId) {
                toggleClass(btn, 'is-visible', 'is-hidden');
            } else {
                toggleClass(btn, 'is-hidden', 'is-visible');
            }
        });
    }

    function hideTestimonialCard() {
        isRegionView = true;
        isDetailCardView = false;

        testimonialsCards.forEach(card => {
            card.style.display = 'none';
        });

        toggleClass(map, regionViewClass, detailCardViewClass);
    }

    function handleTestimonialClose() {
        if (!isShippedView && !isRegionView && activeRegionId) {
            showTestimonialPinMarkers(activeRegionId);
            hideTestimonialCard();

            const regionPath = mapSvg.getElementById(activeRegionId);
            if (regionPath) {
                handlePanzoomRegion(regionPath);
            }

            isDetailCardView = false;
        }
    }

    testimonialsCloseButton.addEventListener('click', handleTestimonialClose);

    testimonialsButtons.forEach(button => {
        button.addEventListener('click', function () {
            if (button.classList.contains('is-visible') && isDetailCardView) {
                handleTestimonialClose();
            } else {
                isRegionView = false;
                isDetailCardView = true;

                const stateId = this.getAttribute('data-state-id');
                const cardId = this.getAttribute('data-card-id');
                const detailCard = document.getElementById(cardId);

                // Hide all detail cards
                testimonialsCards.forEach(card => {
                    card.style.display = 'none';
                });

                // Show the clicked detail card
                if (detailCard) {
                    detailCard.style.display = 'block';
                }

                handlePanzoomTestimonialButton(button, detailCard);

                // Find the region group by matching the stateId
                const matchingRegion = mapStates.find(region => Array.from(region.children).some(state => state.getAttribute('data-state-id') === stateId));

                if (matchingRegion) {
                    // Get the individual states within the matching region group
                    const mapStatesIndividual = Array.from(matchingRegion.children);

                    // Find the matching state within the region group
                    const matchingState = mapStatesIndividual.find(state => state.getAttribute('data-state-id') === stateId);
                    if (matchingState) {
                        // Remove 'is-active' class from all states within the region group
                        mapStatesIndividual.forEach(state => {
                            toggleClass(state, 'is-not-active', 'is-active');
                        });

                        // Add 'is-active' class to the matching state and remove 'is-not-active'
                        toggleClass(matchingState, 'is-active', 'is-not-active');
                    }
                }

                // Hide all buttons except the clicked one
                testimonialsButtons.forEach(btn => {
                    btn.removeAttribute('style'); // remove opacity and pointer event from updateTestimonialButtons()
                    const isClickedButton = btn === this;
                    btn.classList.toggle('is-hidden', !isClickedButton);
                    btn.classList.toggle('is-visible', isClickedButton);
                });

                map.classList.remove(regionViewClass);
                map.classList.add(detailCardViewClass);
            }
        });
    });

    // When user gets back to the default map state
    function resetTestimonialButtons() {
        testimonialsButtons.forEach(btn => {
            btn.classList.remove('is-hidden', 'is-visible');
            btn.style.opacity = '0';
            btn.style.pointerEvents = 'none';

            const stateId = btn.getAttribute('data-state-id');

            const matchingRegion = mapStates.find(region => Array.from(region.children).some(state => state.getAttribute('data-state-id') === stateId));

            if (matchingRegion) {
                const mapStatesIndividual = Array.from(matchingRegion.children);

                const matchingState = mapStatesIndividual.find(state => state.getAttribute('data-state-id') === stateId);
                if (matchingState) {
                    mapStatesIndividual.forEach(state => {
                        state.classList.remove('is-not-active', 'is-active');
                    });
                }
            }

            testimonials.forEach(testimonial => {
                testimonial.style.pointerEvents = 'none';
            });
        });
    }

    function handleResetMap() {
        resetTestimonialButtons();
        hideTestimonialCard();
        handleRegionClasses();
    }

    // Get user to default map state
    mapCloseButton.addEventListener('click', () => {
        if (!isShippedView) {
            handleResetMap();
        }
    });

    // Do not highlight regions when mouse position is outside of our map container
    map.addEventListener('mouseleave', () => {
        if (!(isShippedView || isRegionView || isDetailCardView)) {
            handleRegionClasses();
        }
    });

    // Mobile touch events
    function handleRegionTouch(event, element) {
        const regionId = element.getAttribute('data-region-id');
        const regionPath = mapSvg.getElementById(regionId);
        handlePanzoomRegion(regionPath);

        activeRegionId = regionId;

        const matchingButton = Array.from(navButtons).find(button => button.getAttribute('data-region-id') === regionId);
        if (matchingButton) {
            toggleRegionClass(matchingButton, 'add', notActiveClass);
            map.classList.add(regionViewClass);
            footnote.innerText = 'Select a pin to see details';
            updateTestimonialButtons(regionId);
            isRegionView = true;

            const navToggle = document.querySelector(navToggleHandle);
            if (navToggle) {
                navToggle.innerText = matchingButton.innerText;
            }
        }
    }
    Array.from(mapStates).forEach(state => {
        state.addEventListener('touchstart', event => {
            if (!isShippedView) {
                event.preventDefault();
                handleRegionTouch(event, state);
            }
        });
    });
    Array.from(mapRegions).forEach(region => {
        region.addEventListener('touchstart', event => {
            if (!isShippedView) {
                event.preventDefault();
                handleRegionTouch(event, region);
            }
        });
    });

    // Show mouse position X and Y coordinates on map if Craft user admin is logged in
    function displayCoords({ clientX, clientY }) {
        const { top, left, width, height } = mapCont.getBoundingClientRect();
        const x = clientX - left;
        const y = clientY - top;
        const pX = (x / width) * 100;
        const pY = (y / height) * 100;
        const scale = pz.getScale() || 1;

        mapCoords.style.left = `${(x / scale) + 5}px`;
        mapCoords.style.top = `${(y / scale) + 5}px`;
        mapCoords.style.transform = `scale(${1 / scale})`;
        mapCoords.textContent = `X: ${pX.toFixed(2)}%, Y: ${pY.toFixed(2)}%`;
    }
    if (showCoords) {
        mapCont.addEventListener('pointermove', displayCoords);
        mapCont.addEventListener('pointerdown', displayCoords);
        mapCont.addEventListener('pointerleave', () => { mapCoords.textContent = ''; });
    }
}
