import './polyfills';

import { spy } from 'ui-utilities';
import pop from './components';

// Helpers
import enableEvents from './helpers/analytics';
import enableFootnotes from './helpers/footnotes';
import adoptOrphans from './helpers/adopt';

// Components
import AjaxSearch from './components/ajax-search';
import AnchorNav from './components/anchor-nav';
import BlogsCarousel from './components/blogs-carousel';
// import CareerSearch from './components/career-search';
import CommentsList from './components/comments-list';
import CrossroadsHero from './components/crossroads-hero';
import DetailHero from './components/detail-hero';
import DealerSearch from './components/dealer-search';
import DocumentCard from './components/document-card';
import DocumentDownload from './components/document-download';
import DocumentDownloadCarousel from './components/document-download-carousel';
import DocumentSearch from './components/document-search';
import EventArchiveComments from './components/event-archive-comments';
import EventArchiveHero from './components/event-archive-hero';
import EventArchiveList from './components/event-archive-list';
import FeaturedCourses from './components/featured-courses';
import Form from './components/form';
import Header from './components/header';
import IconList from './components/icon-list';
import IframeDetail from './components/iframe-detail';
import InlineVideo from './components/inline-video';
import Input from './components/input';
import InstagramFeed from './components/instagram-feed';
import ListCtas from './components/list-ctas';
import LoadableListings from './components/loadable-listings';
import Loader from './components/loader';
import Main from './components/main';
import MediaCarousel from './components/media-carousel';
import MediaLibrary from './components/media-library';
import Modal from './components/modal';
import ParallaxImage from './components/parallax-image';
import ProductsSlider from './components/products-slider';
import ProgressBar from './components/progress-bar';
import QuotesCarousel from './components/quotes-carousel';
import RebateDetail from './components/rebate-detail';
import RebateForm from './components/rebate-form';
import RegionsMap from './components/regions-map';
import RegistrationCard from './components/registration-card';
import SalesManagerSearch from './components/sales-manager-search';
import SiteSearch from './components/site-search';
import SpeakerEvents from './components/speaker-events';
import SplitDealerSearch from './components/split-dealer-search';
import Tooltip from './components/tooltip';
import UpcomingEvents from './components/upcoming-events';
import Video from './components/video';
import VideoCopy from './components/video-copy';
import WarrantyDetail from './components/warranty-detail';

// Define map of component handles to component classes
/* eslint-disable quote-props */
const classMap = {
    'ajax-search': AjaxSearch,
    'anchor-nav': AnchorNav,
    'blogs-carousel': BlogsCarousel,
    // 'career-search': CareerSearch,
    'comments-list': CommentsList,
    'crossroads-hero': CrossroadsHero,
    'detail-hero': DetailHero,
    'dealer-search': DealerSearch,
    'document-card': DocumentCard,
    'document-download': DocumentDownload,
    'document-download-carousel': DocumentDownloadCarousel,
    'document-search': DocumentSearch,
    'event-archive-comments': EventArchiveComments,
    'event-archive-hero': EventArchiveHero,
    'event-archive-list': EventArchiveList,
    'featured-courses': FeaturedCourses,
    'form': Form,
    'header': Header,
    'icon-list': IconList,
    'iframe-detail': IframeDetail,
    'inline-video': InlineVideo,
    'input': Input,
    'instagram-feed': InstagramFeed,
    'list-ctas': ListCtas,
    'loadable-listings': LoadableListings,
    'loader': Loader,
    'main': Main,
    'media-carousel': MediaCarousel,
    'media-library': MediaLibrary,
    'modal': Modal,
    'parallax-image': ParallaxImage,
    'products-slider': ProductsSlider,
    'progress-bar': ProgressBar,
    'quotes-carousel': QuotesCarousel,
    'rebate-detail': RebateDetail,
    'rebate-form': RebateForm,
    'regions-map': RegionsMap,
    'registration-card': RegistrationCard,
    'sales-manager-search': SalesManagerSearch,
    'site-search': SiteSearch,
    'speaker-events': SpeakerEvents,
    'split-dealer-search': SplitDealerSearch,
    'tooltip': Tooltip,
    'upcoming-events': UpcomingEvents,
    'video': Video,
    'video-copy': VideoCopy,
    'warranty-detail': WarrantyDetail,
};
/* eslint-enable quote-props */

// Define all actions/commands that components pub/sub
const actions = {
    // Action events
    lockScroll: 'lock-scroll',
    unlockScroll: 'unlock-scroll',
    showHeader: 'show-header',
    hideHeader: 'hide-header',
    closeHeader: 'close-header',
    getHeaderHeight: 'get-header-height',
    showOverlay: 'show-overlay',
    hideOverlay: 'hide-overlay',
    openModal: 'open-modal',
    closeModal: 'close-modal',
    loadModal: 'load-modal',
    showTooltip: 'show-tooltip',
    startDownload: 'start-download',
    startLoader: 'start-loader',
    stopLoader: 'stop-loader',
    showInputErrors: 'show-input-errors',
    resetPage: 'resetPage',
    getCsrfToken: 'get-csrf-token',
    startVideoTimer: 'start-video-timer',
    pauseVideoTimer: 'pause-video-timer',
};

// Event handler functions
function handleDOMConentLoaded() {
    // Functionality for after components initialize
    function cb({ events }) {
        // Check lazy loaded images
        spy.images();
        // Loading animations to sections
        document.querySelectorAll('main header, main section, main footer')
            .forEach(s => s.classList.add('in-view'));
        // Initialize GA events
        enableEvents();
        enableFootnotes({ actions, events });
    }

    // Adopt orphan text before assigning event listeners since this will break events
    adoptOrphans();
    // Call component constructors
    pop({ classMap, actions, cb });
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
