import { scroll } from 'ui-utilities';
import { load } from 'recaptcha-v3';

export default function MediaLibrary(el, {
    gateContHandle,
    forceDownload,
    recaptchaSiteKey,
    turnstileSiteKey,
    actions,
    events,
}) {
    const filtersToggle = el.querySelector('input[type="checkbox"]');
    const filtersToggleLabel = filtersToggle.nextElementSibling;
    const filters = el.querySelectorAll('input[type="radio"]');
    const downloads = el.querySelectorAll('article');
    const gateTriggers = el.querySelectorAll('article button[type="button"]');
    const gate = el.querySelector(gateContHandle);

    filters.forEach(f => {
        f.onchange = () => {
            filtersToggle.checked = false;
            filtersToggleLabel.innerHTML = `
                Filter by:
                <strong>${f.nextElementSibling.textContent}</strong>
                <em><span></span><span></span></em>
            `;
            events.emit(actions.getHeaderHeight, {
                cb: headerHeight => {
                    scroll.top(filtersToggle.closest('div'), headerHeight);
                    downloads.forEach(d => {
                        if (f.value === '') {
                            d.style.display = 'block';

                            return;
                        }

                        d.style.display = d.getAttribute('data-filters').split(',').includes(f.value)
                            ? 'block'
                            : 'none';
                    });
                },
            });
        };
    });
    gateTriggers.forEach(t => {
        t.onclick = () => {
            const { resourceId } = t.closest('article').dataset;

            gate.querySelector('form').insertAdjacentHTML(
                'beforeend',
                `<input type="hidden" name="resourceId" value="${resourceId}">`,
            );
            events.emit(actions.loadModal, {
                markup: gate.outerHTML,
                size: 'gate',
            });
        };
    });
    document.addEventListener('click', e => {
        if (e.target.matches(`${gateContHandle} [type="button"]`)) {
            e.preventDefault();
            events.emit(actions.closeModal);
        }
        if (e.target.matches(`${gateContHandle} [type="submit"]`)) {
            e.preventDefault();
            e.target.disabled = true;

            const form = e.target.closest('form');
            const formData = new FormData(form);

            load(recaptchaSiteKey).then(r => {
                r.execute('form').then(recaptchaToken => {
                    formData.append('recaptchaToken', recaptchaToken);
                    turnstile.ready(() => { // eslint-disable-line no-undef
                        turnstile.render(form, { // eslint-disable-line no-undef
                            sitekey: turnstileSiteKey,
                            action: 'rebate',
                            appearance: 'interaction-only',
                            size: 'compact',
                            callback(turnstileToken) {
                                formData.append('turnstileToken', turnstileToken);
                                fetch('/', {
                                    method: form.method,
                                    body: formData,
                                    headers: { Accept: 'application/json' },
                                }).then(res => res.json().then(json => ({
                                    status: res.status,
                                    ...json,
                                }))).then(({
                                    status,
                                    errors = null,
                                    message = '',
                                }) => {
                                    // Reset all field errros to empty
                                    Array.from(formData.keys()).forEach(name => {
                                        events.emit(actions.showInputErrors, {
                                            name,
                                            errors: [],
                                            formId: form.id,
                                        });
                                    });
                                    e.target.disabled = false;

                                    switch (status) {
                                    case 400:
                                        Object.entries(errors).forEach(([name, errs]) => {
                                            events.emit(actions.showInputErrors, {
                                                name,
                                                errors: errs,
                                                formId: form.id,
                                            });
                                        });

                                        if (message !== '' && errors === null) {
                                            window.alert(message); // eslint-disable-line no-alert
                                        }

                                        break;
                                    case 200:
                                    default:
                                        form.submit();

                                        break;
                                    }
                                });
                            },
                        });
                    });
                });
            });
        }
    });

    if (forceDownload) {
        const form = el.querySelector(`article[data-resource-id="${forceDownload}"] form`);

        if (form) {
            form.submit();
        }
    }
}
